import { Box } from "@mui/material";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
// import { useEffect, useRef, useState } from "react";
// import Image from "next/image";

// import FirstFrame from "./assets/first_frame.webp";

const VideoBackground = ({ children }) => {
  // const [showImage, setShowImage] = useState(true);

  // const videoRef = useRef();

  // useEffect(() => {
  //   if (videoRef?.current) {
  //     const readyState = videoRef?.current?.readyState;

  //     if (readyState === 4) {
  //       setShowImage(false);
  //       videoRef.current.play();
  //     }
  //   }

  //   return () => {
  //     setShowImage(true);
  //   };
  // }, [videoRef?.current?.readyState]);

  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const enableVideo = () => {
      setTimeout(() => {
        setShowVideo(true);
      }, 100);
    };

    enableVideo();

    return () => {
      setShowVideo(false);
    };
  }, []);

  return (
    <Box
      position="relative"
      display="grid"
      height="calc(100vh - 40px)"
      width="100%"
      mb="40px"
    >
      <video
        // ref={videoRef}
        className={style.fullscreen}
        preload="true"
        playsInline
        autoPlay
        loop
        muted
        poster={"/images/first_frame.webp"}
      >
        {showVideo ? (
          <source src="/videos/DOMMI.webm" type="video/webm" />
        ) : null}
      </video>
      {/* {showImage ? (
        <Image src={FirstFrame} className={style.fullscreen} />
      ) : null} */}
      <Box
        zIndex="1"
        // backgroundColor="rgba(0, 0, 0, 0.1)"
        display="flex"
      >
        {children}
      </Box>
    </Box>
  );
};

export default VideoBackground;
