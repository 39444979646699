import cs from "classnames";
import { useIntersectionObserver } from "hooks/useIntersectionObserver";

const Fade = ({ children, ...rest }) => {
  const { ref, shouldStayVisible } = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  return (
    <section
      ref={ref}
      className={cs("fade", shouldStayVisible ? "visible" : "")}
      {...rest}
    >
      {children}
    </section>
  );
};

export default Fade;
