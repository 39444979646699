import { useState, useEffect } from "react";

const useComponentSize = () => {
  const [ref, setRef] = useState(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    if (ref) {
      setSize({
        width: ref.offsetWidth,
        height: ref.offsetHeight,
      });
    }
  };

  useEffect(() => {
    if (ref) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  const refCallback = (node) => {
    setRef(node);
  };

  return [refCallback, size];
};

export default useComponentSize;
