import dynamic from "next/dynamic";

import Main from "./sections/Main";

import Comfort from "./sections/Comfort";
import Latest from "./sections/Latest";
// const HelloInDommi = dynamic(() => import("./sections/HelloInDommi"));

const Specials = dynamic(() => import("./sections/Specials"));
const Categories = dynamic(() => import("./sections/Categories"));
// const SelectFlat = dynamic(() => import("./sections/SelectFlat"));
const FormContact = dynamic(() => import("./sections/FormContact"));

const HomePage = ({ dataLatest, dataSpecials }) => {
  return (
    <>
      <Main />

      <Comfort />
      {/* <HelloInDommi /> */}
      <Latest data={dataLatest} />
      <Specials data={dataSpecials} />
      <Categories />
      {/* <Categories /> */}
      {/* <SelectFlat /> */}
      <FormContact />
    </>
  );
};

export default HomePage;
