import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Seo from "components/Seo";
import Layout from "components/Organisms/Layout";

import DictionaryService from "../src/services/DictionaryService";
import PropertiesService from "../src/services/PropertiesService";

import HomePage from "../src/pages/Home";
import { isArray } from "../src/utils/functions";

export default function Home({ dataLatest, dataSpecials, dictionaries }) {
  return (
    <>
      <Layout dictionaries={dictionaries}>
        <Seo description="DOMMI" />
        <HomePage dataLatest={dataLatest} dataSpecials={dataSpecials} />
      </Layout>
    </>
  );
}

export const getServerSideProps = async (context) => {
  const { locale } = context;

  let dictionaries = null;

  let data = null;
  let dataLatest = null;
  let dataSpecials = null;

  try {
    const res = await PropertiesService.getProperties({
      order: "id,desc",
      limit: 5,
    });

    if (
      res?.status === 200 &&
      isArray(res?.data?.data) &&
      res?.data?.data?.length > 0
    ) {
      dataLatest = res?.data?.data;
    }
  } catch (err) {
    console.log(err);
  }

  try {
    // Na razie type nie ma w api
    const res = await PropertiesService.getProperties({
      order: "id,asc",
      advantages: "Oferta specjalna",
      limit: 5,
    });

    if (
      res?.status === 200 &&
      isArray(res?.data?.data) &&
      res?.data?.data?.length > 0
    ) {
      dataSpecials = res?.data?.data;
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const response = await DictionaryService.getDictionary();

    if (response?.status === 200) {
      dictionaries = response?.data;
    }
  } catch (error) {
    console.log(error);
  }

  return {
    props: {
      data,
      dataLatest,
      dataSpecials,
      dictionaries,
      ...(await serverSideTranslations(locale, [
        "navigation",
        "common",
        "property",
        "filters",
        "home",
      ])),
    },
  };
};
