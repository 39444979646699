import { useMemo } from "react";
import ReactSlick from "react-slick";

import { useBreakpoint } from "hooks/useBreakpoint";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import theme from "theme";

const boxStyle = {
  height: 36,
  width: 36,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  transition: "all 0.2s ease-in-out",
};

function SampleNextArrow(props) {
  const { className, onClick, color } = props;

  const disable = useMemo(() => {
    if (className.includes("slick-disabled")) {
      return true;
    } else {
      return false;
    }
  }, [className]);

  return (
    <Box
      className={"slick-arrow slick-next"}
      style={{
        ...boxStyle,
        border: `2px solid ${color}`,
        opacity: disable ? 0.5 : 1,
        color: color,
        right: -50,
      }}
      onClick={onClick}
    >
      <ArrowForwardIos />
    </Box>
  );
}
function SamplePrevArrow(props) {
  const { className, onClick, color } = props;

  const disable = useMemo(() => {
    if (className.includes("slick-disabled")) {
      return true;
    } else {
      return false;
    }
  }, [className]);

  return (
    <Box
      className={"slick-arrow slick-prev"}
      style={{
        ...boxStyle,
        border: `2px solid ${color}`,
        opacity: disable ? 0.5 : 1,
        color: color,
        left: -50,
      }}
      onClick={onClick}
    >
      <ArrowBackIosNew />
    </Box>
  );
}

const Slider = ({ children, options = {}, color = "primary" }) => {
  const { breakpoint } = useBreakpoint();

  const getSliderToShow = useMemo(() => {
    let slidesToShow = 3;
    let visibleArray = true;

    switch (true) {
      case breakpoint === "xs":
        slidesToShow = 1;
        visibleArray = false;
        break;

      case breakpoint === "sm":
        slidesToShow = 1;
        visibleArray = false;
        break;

      case breakpoint === "md":
        slidesToShow = 2;
        visibleArray = false;
        break;
      case breakpoint === "lg":
        slidesToShow = 3;
        visibleArray = true;
        break;
      default:
        slidesToShow = 3;
        visibleArray = true;
        break;
    }

    return { slidesToShow, visibleArray };
  }, [breakpoint, children]);

  const switchColor = useMemo(() => {
    switch (color) {
      case "primary":
        return theme.palette.text.primary;
      case "secondary":
        return theme.palette.text.secondary;
      default:
        return theme.palette.text.primary;
    }
  }, [color]);

  const settings = {
    infinite: false,
    leazyLoad: true,
    slidesToShow: getSliderToShow.slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: getSliderToShow.visibleArray,
    nextArrow: <SampleNextArrow color={switchColor} />,
    prevArrow: <SamplePrevArrow color={switchColor} />,
    dots: true,
    customPaging: (i) => (
      <Box className="slick__dots__wrapper--custom">
        <Box
          className="slick__dots--custom"
          backgroundColor={switchColor}
        ></Box>
      </Box>
    ),

    ...options,
  };

  return <ReactSlick {...settings}>{children}</ReactSlick>;
};

export default Slider;
