import { useMemo } from "react";
import Image from "next/image";
import { Box } from "@mui/material";

import DogImage from "../../../../../../assets/images/dog.jpeg";
import ChildImage from "../../../../../../assets/images/child.jpeg";
import { useBreakpoint } from "hooks/useBreakpoint";

const Images = () => {
  const { breakpoint } = useBreakpoint();
  const sizesImages = useMemo(() => {
    let sizes = {
      image1: {
        width: 335,
        height: 555,
        image: ChildImage,
      },
      image2: {
        width: 335,
        height: 400,
        positionRight: 300,
        image: DogImage,
      },
    };
    if (breakpoint === "md") {
      sizes.image1.height = 450;
      sizes.image1.width = 300;
      sizes.image2.positionRight = 0;
      sizes.image2.width = 300;
    }
    return sizes;
  }, [breakpoint]);

  return (
    <Box
      position="relative"
      flexBasis="50%"
      display={{ xs: "none", md: "block" }}
    >
      <Box position="absolute" top={0} right={0}>
        <Box
          width={sizesImages.image1.width}
          height={sizesImages.image1.height}
          position="relative"
          borderRadius={4}
          overflow="hidden"
          sx={{
            " img": {
              transition: "all 0.4s ease-in-out",
            },
            ":hover img": {
              transform: "scale(1.1)",
            },
          }}
        >
          <Image
            height={sizesImages.image1.height}
            alt="Mountains"
            src={sizesImages.image1.image}
            style={{
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>

      <Box
        position="absolute"
        bottom={0}
        right={sizesImages.image2.positionRight}
      >
        <Box
          width={sizesImages.image2.width}
          height={sizesImages.image2.height}
          position="relative"
          borderRadius={4}
          overflow="hidden"
          sx={{
            " img": {
              transition: "all 0.4s ease-in-out",
            },
            ":hover img": {
              transform: "scale(1.1)",
            },
          }}
        >
          <Image
            alt="Mountains"
            src={sizesImages.image2.image}
            sizes="100%"
            fill={true}
            style={{
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Images;
