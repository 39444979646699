import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Template from "pages/Home/components/Template";

import Images from "./components/Images";
import Button from "components/Atoms/Button";
import theme from "theme";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const Comfort = () => {
  const { t } = useTranslation("home");
  const router = useRouter();
  const checkboxListElements = [
    "We do not charge commissions",
    "We tailor the offer to your needs",
    "We guarantee rental security",
    "We provide professional service",
  ];

  return (
    <Template id={"comfort"}>
      <Box
        display="flex"
        paddingLeft={{ xs: 2, lg: 0 }}
        paddingRight={{ xs: 2, lg: 0 }}
      >
        <Box
          flexBasis={{
            xs: "100%",
            lg: "50%",
          }}
        >
          <Typography fontSize="3.5rem" fontWeight={600}>
            {t("Live comfortably with Dommi.title")}
          </Typography>
          <Typography fontSize="3.5rem" fontWeight={400}>
            {t("Live comfortably with Dommi.desc")}
          </Typography>
          <Typography fontSize="1.7rem" py={8} fontWeight={400} maxWidth="75%">
            {t("Your satisfaction is our priority...")}
          </Typography>
          <Box>
            {checkboxListElements?.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                fontSize="1.3rem"
                columnGap={2}
                py={1}
              >
                <CheckCircleOutlineIcon />
                <Typography fontWeight={600}>{t(item)}</Typography>
              </Box>
            ))}
          </Box>
          <Button
            onClick={() => router.push("/about")}
            sx={{ mt: 8, width: "50%" }}
          >
            {t("Read more")}
          </Button>
        </Box>
        <Images />
      </Box>
    </Template>
  );
};

export default Comfort;
