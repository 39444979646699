import { Box, Container, Typography } from "@mui/material";

import VideoBackground from "./components/VideoBackground";
import SearchBox from "./components/SearchBox";
import ScrollNextButton from "./components/ScrollNextButton";
import useWindowSize from "hooks/useWindowSize";
import { useMemo } from "react";
import { useTranslation } from "next-i18next";

const Main = () => {
  const { height } = useWindowSize();
  const { t } = useTranslation("home");

  const getOptions = useMemo(() => {
    let marginTop = 40;
    let showNextButton = true;

    if (height < 700) {
      marginTop = 30;
    }
    if (height < 550) {
      showNextButton = false;
    }

    return { marginTop, showNextButton };
  }, [height]);

  return (
    <VideoBackground>
      <Container maxWidth={false} fixed>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          rowGap={4}
          marginTop={getOptions.marginTop}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Typography
            variant="h1"
            color="white"
            fontWeight="400"
            px={{
              xs: 4,
              lg: 0,
            }}
          >
            {t("With us, you will find")} <br />
            <Typography component="span" color="white" fontWeight="600">
              {t("your dream home")}
            </Typography>
          </Typography>
          <SearchBox />
        </Box>
      </Container>
      {getOptions.showNextButton ? <ScrollNextButton /> : null}
    </VideoBackground>
  );
};

export default Main;
