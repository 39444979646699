import { KeyboardArrowDown } from "@mui/icons-material";
import { Box } from "@mui/material";

const ScrollNextButton = () => {
  const handleClickScroll = () => {
    const id = "comfort";
    const yOffset = -150;
    const element = document.getElementById(id);
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <Box
      onClick={handleClickScroll}
      position="absolute"
      bottom={20}
      left="50%"
      border={2}
      borderRadius={100}
      height={64}
      width={64}
      display={{ xs: "none", md: "flex" }}
      alignItems="center"
      justifyContent="center"
      style={{ transform: "translateX(-50%)", borderColor: "white" }}
      sx={{
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        color: "white",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,0.2)",
        },
      }}
    >
      <KeyboardArrowDown sx={{ color: "inherit", fontSize: 48 }} />
    </Box>
  );
};

export default ScrollNextButton;
