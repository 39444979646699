import { useMemo, useState, Suspense } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useForm, Controller } from "react-hook-form";
import { useStoreState } from "easy-peasy";
import { Box, TextField, Typography, Slider } from "@mui/material";
import { Search as SearchIcon, Tune as TuneIcon } from "@mui/icons-material";
import dayjs from "dayjs";

import Button from "components/Atoms/Button";
import Select from "components/Modules/Select";
import SelectControlled from "components/controlled/SelectControlled";

import { defaultValues, marksButtonGroup } from "utils/defaults";
import { convertToApi } from "utils/conventersApi";
import {
  convertNameOfCountRooms,
  convertPrice,
  isArray,
  isNumeric,
} from "utils/functions";
import useWindowSize from "hooks/useWindowSize";
import theme from "theme";
import DropdownFilter from "./components/DropdownFilter";
import ButtonGroupControlled from "components/controlled/ButtonGroupControlled";

const AdvenceFiltersModal = dynamic(() =>
  import("./components/AdvenceFiltersModal", {
    suspense: true,
    ssr: false,
  })
);

const SearchBox = ({ investmentList }) => {
  const [openModal, setOpenModal] = useState(false);

  const investmentsList = useStoreState(
    (store) => store?.dictionary?.payload?.investmentsList
  );

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: defaultValues,
  });

  const router = useRouter();
  const { t } = useTranslation(["filters", "common", "navigation"]);
  const { t: tNavigation } = useTranslation(["navigation"]);
  const { height } = useWindowSize();

  const onSubmit = (data) => {
    const clearData = convertToApi({
      ...data,
      available_from: data?.available_from
        ? dayjs(data?.available_from).format("YYYY-MM-DD")
        : null,
    });

    router.push({
      pathname: `/${tNavigation("navigationPaths.search")}`,
      query: { ...clearData },
    });
  };

  const elementSize = useMemo(() => {
    let paddingWrapper = 6;
    let fontSize = 18;
    let selectSize = "medium";
    let rowGap = 3;

    if (height < 700) {
      paddingWrapper = 4;
      fontSize = 16;
      selectSize = "small";
      rowGap = 2;
    }

    return { paddingWrapper, fontSize, selectSize, rowGap };
  }, [height]);

  const { price, area, rooms } = watch();

  const displayPrice = useMemo(() => {
    if (!price || JSON.stringify(price) === JSON.stringify(defaultValues.price))
      return null;
    return `${convertPrice(price[0])} PLN - ${convertPrice(price[1])} PLN`;
  }, [price]);

  const displayArea = useMemo(() => {
    if (!area || JSON.stringify(area) === JSON.stringify(defaultValues.area))
      return null;

    let from = null;
    let to = null;

    if (area[0]) from = area[0];
    if (area[1]) to = area[1];

    if (from && !to) return `${t("from")} ${from} m2`;
    if (!from && to) return `0 - ${to}`;

    if (!from && !to) return null;

    return `${from} m2 - ${to} m2`;
  }, [area?.[0], area?.[1]]);

  const displayRooms = useMemo(() => {
    if (!rooms || JSON.stringify(rooms) === JSON.stringify(defaultValues.rooms))
      return null;

    let from = null;
    let to = null;

    if (rooms[0] || isNumeric(rooms[0])) from = rooms[0]?.toString();
    if (rooms[1] || isNumeric(rooms[0])) to = rooms[1]?.toString();
    if (from === "99") from = "5+";
    if (to === "99") to = "5+";

    if (from === to && from === "99")
      return `5+ ${convertNameOfCountRooms(from)}`;
    if (from === to) return `${from} ${convertNameOfCountRooms(from)}`;

    return `${[from, to]?.filter((el) => el).join(" - ")}`;
  }, [rooms?.[0], rooms?.[1]]);

  return (
    <Box
      backgroundColor={theme.palette.background.default}
      borderRadius={4}
      px={4}
      py={elementSize.paddingWrapper}
      minWidth={360}
      sx={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Typography fontSize={elementSize.fontSize} fontWeight={600}>
        {tNavigation("Find an apartment")}
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          height="100%"
          rowGap={elementSize.rowGap}
          mt={elementSize.rowGap}
        >
          <SelectControlled
            control={control}
            name="location"
            id="Select-SearchBox-Location"
            placeholder={t("Location")}
            options={isArray(investmentsList) ? investmentsList : []}
            isMulti
            maxWidth={400}
          />

          <DropdownFilter label={t("Price")} value={displayPrice}>
            <Box>
              <Controller
                control={control}
                name="price"
                defaultValue={50}
                render={({ field: { value, onChange } }) => (
                  <Box px={4} my={1}>
                    <Slider
                      getAriaLabel={() => "Minimum distance shift"}
                      valueLabelDisplay="off"
                      min={0}
                      max={10000}
                      step={100}
                      disableSwap
                      onChange={onChange}
                      value={value}
                    />
                  </Box>
                )}
              />
            </Box>
            <Box display="flex" columnGap={2}>
              <Controller
                control={control}
                name="price"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    label={t("from")}
                    id="from"
                    type="number"
                    onChange={(v) => onChange([v.target.value, value[1]])}
                    value={value[0]}
                    InputProps={{
                      inputProps: { min: 0, step: 100 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="price"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    label={t("to")}
                    id="from"
                    type="number"
                    onChange={(v) => onChange([value[0], v.target.value])}
                    value={value[1]}
                    InputProps={{
                      inputProps: { min: 0, step: 100 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Box>
          </DropdownFilter>
          <DropdownFilter label={t("Area")} value={displayArea}>
            <Box display="flex" columnGap={2}>
              <TextField
                fullWidth
                placeholder={t("from")}
                id="from"
                type="number"
                size="small"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                {...register("area.0", { valueAsNumber: true })}
              />
              <TextField
                fullWidth
                placeholder={t("to")}
                id="from"
                type="number"
                size="small"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                {...register("area.1", { valueAsNumber: true })}
              />
            </Box>
          </DropdownFilter>
          <DropdownFilter label={t("Rooms")} value={displayRooms}>
            <Box>
              <ButtonGroupControlled
                control={control}
                name="rooms"
                marks={marksButtonGroup}
              />
            </Box>
          </DropdownFilter>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          columnGap={2}
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenModal(true)}
        >
          <TuneIcon />
          <Typography my={elementSize.rowGap}>
            {t("Advanced search")}
          </Typography>
        </Box>

        <Button
          type="submit"
          fullWidth
          sx={{
            padding:
              elementSize.selectSize === "small" ? "0.75rem 1rem" : "1rem 2rem",
          }}
        >
          <SearchIcon sx={{ mr: 2 }} />
          {t("search")}
        </Button>
      </Box>
      {openModal ? (
        <Suspense fallback={<div>Loading...</div>}>
          <AdvenceFiltersModal
            open={openModal}
            setOpen={setOpenModal}
            investmentList={investmentList}
            register={register}
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Suspense>
      ) : null}
    </Box>
  );
};

export default SearchBox;
