import { useEffect, useRef, useState } from "react";

export function useIntersectionObserver(options = {}) {
  const { threshold = 1, root = null, rootMargin = "0%" } = options;
  const ref = useRef(null);
  const [entry, setEntry] = useState(null);
  const [shouldStayVisible, setShouldStayVisible] = useState(false);

  useEffect(() => {
    const node = ref?.current;

    if (!node || typeof IntersectionObserver !== "function") {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (shouldStayVisible) {
          return;
        }

        setEntry(entry);

        if (entry.isIntersecting) {
          setShouldStayVisible(true);
        }
      },
      { threshold, root, rootMargin }
    );

    observer.observe(node);

    return () => {
      setEntry(null);
      observer.disconnect();
    };
  }, [threshold, root, rootMargin, shouldStayVisible]);

  return { ref, shouldStayVisible, entry };
}
