import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Fade, Menu, Typography } from "@mui/material";
import useComponentSize from "hooks/useComponentSize";
import { useState } from "react";

import theme from "theme";

const DropdownFilter = ({ children, label = "", value }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [ref, size] = useComponentSize();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        ref={ref}
        onClick={handleClick}
        display="flex"
        justifyContent="space-between"
        sx={{
          border: "1px solid #ddeff5 ",
          cursor: "pointer",
          borderRadius: 2,
          overflow: "hidden",
          borderColor: "#ddeff5",
          boxShadow: "none",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            borderColor: "#cae6ef",
            boxShadow: "0 0 6px 1px #cae6ef",
          },
        }}
      >
        <Typography
          sx={{
            px: 2,
            py: 2.5,
            width: "100%",
            backgroundColor: "#fff",
            color: value ? theme.palette.text.primary : "rgba(0,0,0,0.55)",
          }}
        >
          {value ? value : label}
        </Typography>
        <Box p={1} display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            borderRadius={1}
            mx={1.4}
            sx={{
              color: "hsl(0, 0%, 80%)",
              transition: "all 0.2s ease-in-out",
              ":hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.tertiary.contrastText,
              },
            }}
          >
            <KeyboardArrowDown />
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        TransitionComponent={Fade}
        PaperProps={{
          sx: {
            width: size.width,
            minHeight: size.height,
            mt: 1,
            px: 2,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default DropdownFilter;
