import { useMemo } from "react";
import { Box, Container, Typography } from "@mui/material";

import Fade from "components/Atoms/Fade";

import theme from "theme";

const Template = ({ children, title, subTitle, text, id, backgroundColor }) => {
  const getBackgroundColor = useMemo(() => {
    let colors = {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    };

    switch (backgroundColor) {
      case "primary":
        break;
      case "secondary":
        colors = {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.secondary,
        };
        break;

      default:
        break;
    }

    return colors;
  }, [backgroundColor]);

  return (
    <Fade>
      <Box
        backgroundColor={getBackgroundColor.backgroundColor}
        pt={{ xs: 4, lg: 10 }}
        pb={{ xs: 8, lg: 20 }}
      >
        <Container maxWidth={false} fixed id={id}>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            alignItems="center"
            textAlign="center"
            my={{ xs: 4, lg: 8 }}
            px={{ xs: 2, lg: 0 }}
          >
            {title ? (
              <Typography
                fontSize={40}
                fontWeight={600}
                color={getBackgroundColor.color}
                lineHeight={1}
              >
                {title}
              </Typography>
            ) : null}
            {subTitle ? (
              <Typography
                fontSize={38}
                color={getBackgroundColor.color}
                lineHeight={1}
              >
                {subTitle}
              </Typography>
            ) : null}
            {text ? (
              <Typography
                fontSize={20}
                fontWeight={400}
                maxWidth="md"
                color={getBackgroundColor.color}
              >
                {text}
              </Typography>
            ) : null}
          </Box>
          {children}
        </Container>
      </Box>
    </Fade>
  );
};

export default Template;
